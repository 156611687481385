.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-template: 1fr / 1fr;
  width: 100%;
  aspect-ratio: 1/1;
}

.loading-spinner {
  width: 100%;
  height: 100%;
  border: 1px solid #f4f2f2; /* Light grey */
  border-top: 2px solid rgb(88, 90, 245); /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
